import React, { useEffect, useState, useRef } from 'react';
import './stylinghrscroll.css';
import { animated, useSpring } from 'react-spring';
import { useScroll } from 'react-use-gesture';
import Typography from '@material-ui/core/Typography';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ProductModal from '../ExploreProducts/productModal';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setScrollsSubData } from '../../store/actions/homedata';
import { useSelector } from 'react-redux';
import HomePageContent from '../../constants/HomeContent';
import { useMediaQuery } from '@material-ui/core/';
import {
  getFirestore,
  collection,
  getDocs,
  query as firestoreQuery,
  orderBy,
  limit,
  where,
} from 'firebase/firestore';
import app from '../../config/fbConfig';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const HrScrollOptional = (props) => {
  const data = useSelector((state) => state.subscrolldata[props?.name] || []);
  const [description, setDescription] = useState('');
  const dispatch = useDispatch();
  const mdDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const isMobile = useMediaQuery('(max-width: 768px)');
  const repeatComponent = new Array(5).fill(null);

  useEffect(() => {
    if (!data.length) {
      const fetchProductsData = async () => {
        try {
          const db = getFirestore(app);
          const collectionRef = collection(db, 'products');
          const productQuery = firestoreQuery(collectionRef, orderBy('date', 'desc'));
          const filteredProductQuery = firestoreQuery(productQuery, where('type', '==', props.name));
          const limitedProductQuery = firestoreQuery(filteredProductQuery, limit(7));
          const snapshot = await getDocs(limitedProductQuery);
          const products = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          dispatch(setScrollsSubData(props.name, products));
        } catch (error) {
          console.log('Error getting documents: ', error);
        }
      };

      fetchProductsData();
    }
  }, [dispatch, data]);

  const [style, set] = useSpring(() => ({
    transform: 'perspective(500px) rotateY(0deg)',
  }));
  const isSmallScreen = window.innerWidth < 1000;

  const bind = useScroll((event) => {
    if (isSmallScreen) {
      set({
        transform: `perspective(500px) rotateY(${event.scrolling ? event.delta[0] * 0.3 : 0}deg)`,
      });
    } else {
      set({
        transform: `perspective(500px) rotateY(${event.scrolling ? event.delta[0] : 0}deg)`,
      });
    }
  });
  const containerRef = useRef(null);

  const history = useHistory();

  const handleImageClick = (index) => {
    history.push({
      pathname: `/products/${index.title.replace(/\s/g, '-').toLowerCase()}`,
      state: { data: index },
    });
    window.scrollTo(0, 0);
  };
  const scrollForward = () => {
    const container = containerRef.current;
    const scrollAmount = container.clientWidth; // Scroll one container width at a time
    const duration = 500; // Duration of the scroll animation in milliseconds

    const start = container.scrollLeft;
    const end = start + scrollAmount;
    const startTime = performance.now();

    function scroll(timestamp) {
      const currentTime = timestamp - startTime;
      const progress = Math.min(currentTime / duration, 1);

      container.scrollLeft = start + progress * (end - start);

      if (progress < 1) {
        window.requestAnimationFrame(scroll);
      }
    }

    window.requestAnimationFrame(scroll);
  };


  return (
    <>
      <div className="other-container">
        <ArrowForward style={{ fontSize: 30, color: '#c4b205' }} />
        <Typography className="other" style={{ color: '#c4b205', fontWeight: '600', fontFamily: 'Tahoma  ' }} variant="h4">
          {props.name}
        </Typography>
      </div>
      <Typography style={{ marginLeft: '5%', padding: '20px', paddingRight: "0", color: '#C4C1C1', fontWeight: 'lighter', marginRight: "5%" }} variant="body1">
        {HomePageContent[props.name]}
      </Typography>
      <div className="container" {...bind()} ref={containerRef}>
        {data.length !== 0 ? (
          data.map((index, _) => (
            <div key={index.image} onClick={() => handleImageClick(index)} style={{ marginTop: isMobile ? "10px" : `${_ % 2 === 0 ? 0 : 20}px` }}>
              <animated.div
                className="card"
                style={{
                  ...style,
                  backgroundImage: `url(${index.image})`,
                  cursor: 'pointer',
                }}
              />
              <h3 className="capitalize-first-letter" style={{ textAlign: 'center', color: 'white', fontWeight: 'lighter' }}>
                {index.title}
              </h3>
            </div>
          ))
        ) : (

          repeatComponent.map((_, index) => (
            <div style={{ marginTop: isMobile ? "10px" : `${index % 2 === 0 ? 0 : 20}px` }}>
              <div key={index}  {...bind()}>
                <animated.div
                  className="card"
                  style={{
                    ...style,
                    backgroundImage: null,
                    cursor: "pointer",
                  }}
                >

                  <SkeletonTheme baseColor="#e0e0e0" highlightColor="#c4b205">
                    <Skeleton height={200} style={{ marginTop: isMobile ? "10px" : `${_ % 2 === 0 ? 0 : 20}px` }} />
                  </SkeletonTheme>
                </animated.div>
              </div>
            </div>
          ))
        )}
      </div>
      {!mdDown && <div style={{ position: "relative", paddingLeft: "5px" }}>
        <ArrowForward className='arrowforward' style={{ fontSize: "2.0em" }} onClick={scrollForward} />
      </div>}
    </>
  );
};

export default HrScrollOptional;
