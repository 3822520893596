import React, { useState, useRef, useEffect } from "react";
import { makeStyles, withTheme } from "@material-ui/core/styles";
import { Button, Grid, Box, TextField, Slider } from '@material-ui/core'
import AvatarEditor from "react-avatar-editor";
import defaultImage from '../../resources/icons/placeholderImage.png'
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import { withStyles, } from '@material-ui/core/styles';
import { createProduct } from "../../store/actions/productActions";
import * as yup from 'yup'
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import MuiAlert from "@material-ui/lab/Alert";
import { storage } from '../../config/fbConfig'
import Snackbar from "@material-ui/core/Snackbar";

const productSchema = yup.object({
    title: yup.string().required().label('Title'),
    price: yup.string().required().label('Price'),
    description: yup.string().required().label('Description'),
    warranty: yup.number().required().label('Warranty'),
})

const CreateProductModal = props => {
    const dispatch = useDispatch()
    const uploadStatus = useSelector(state => state.product.status);

    const imageCropper = useRef(null)
    const classes = useStyles();

    const [state, setState] = useState({
        file: '',
        imagePreviewUrl: '',
    })
    const [isloading, setloading] = useState(false)
    const [imageError, setimageError] = useState(false)
    const [dpUrl, setDpUrl] = useState('')
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setimageError(false);
    };
    const handleUpload = (base64Image, imageName) => {
        // Create a reference to the storage location
        const uploadTask = storage.ref(`images/${imageName}`).putString(base64Image, 'data_url');
        return uploadTask
            .then(() => {
                return storage
                    .ref("images")
                    .child(imageName)
                    .getDownloadURL();
            })
            .catch((error) => {
                console.log(error);
                throw new Error('Image upload failed');
            });
    };


    useEffect(() => {
        if (uploadStatus === 'success') {
            console.log("code is runned please check")
            setloading(false)
            props.onClose()
            dispatch({ type: 'SET_STATUS_NOSUCCESS' });
        }
    }, [uploadStatus])

    const onChangeHandler = (e) => {
        if (e.target.files.length !== 0) {
            e.preventDefault();
            let reader = new FileReader();
            let file = e.target.files[0];

            reader.onloadend = () => {
                setState({
                    file: file,
                    imagePreviewUrl: reader.result
                });
            }

            reader.readAsDataURL(file)
        }
    }


    let croppedImage = ''
    const setimageCropper = (editor) => {
        croppedImage = editor
    }

    let $imagePreview = null;
    if (state.imagePreviewUrl) {
        $imagePreview = (<AvatarEditor
            ref={setimageCropper}
            image={state.imagePreviewUrl}
            width={500}
            height={350}
            color={[0, 0, 0, 0.5]} // RGBA
            border={0}
            scale={1.0}
            rotate={0}
        />);
    } else {
        $imagePreview = (<AvatarEditor
            image={defaultImage}
            width={500}
            height={350}
            color={[0, 0, 0, 0.5]} // RGBA
            border={0}
            scale={1.0}
            rotate={0}
        />);
    }

    return (
        <Dialog fullScreen open={props.open} onClose={props.onClose} TransitionComponent={props.TransitionComponent}
            PaperProps={{
                style: {
                    backgroundColor: '#121212',
                },
            }}>
            <Snackbar open={imageError} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity="error">
                    Cannot save a product without an image. Click on upload image to select an image from your computer
                </Alert>
            </Snackbar>

            <Formik
                initialValues={{
                    title: '',
                    price: '',
                    description: '',
                    warranty: 0,
                }}
                validationSchema={productSchema}
                onSubmit={async (values, actions) => {

                    if (imageCropper) {
                        try {
                            const canvasScaled = croppedImage.getImageScaledToCanvas().toDataURL("image/png");
                            setloading(true)
                            const imageName = `Dp_${values.title}_${Date.now()}.png`; // Unique image name
                            const downloadUrl = await handleUpload(canvasScaled, imageName);

                            await dispatch(createProduct({ ...values, type: props.product, image: downloadUrl, date: Date.now() }, props.onsucess))
                        }
                        catch (e) {
                            setimageError(true)
                            console.log(e)
                        }
                    }
                    else {
                        console.log('errah!')
                    }

                }}>
                {
                    (formikProps) => (
                        <div>
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={props.onClick} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        {isloading ? 'Saving' : 'Add'} {props.title}
                                    </Typography>
                                    {isloading ?
                                        <Loader
                                            visible={true}
                                            type="Bars"
                                            color='white'
                                            height={50}
                                            width={50}
                                            timeout={3000} //3 secs
                                        /> : null}
                                    {!isloading ? <Button autoFocus color="inherit" onClick={formikProps.handleSubmit}>
                                        save
                                    </Button> : null}
                                    {/* {isloading ? (
                                        <Loader
                                            visible={true}
                                            type="Bars"
                                            color='white'
                                            height={50}
                                            width={50}
                                            timeout={3000} //3 secs
                                        />
                                        ) : (
                                        <Button autoFocus color="inherit" onClick={() => {formikProps.handleSubmit(); }}>
                                            save
                                        </Button>
                                        )} */}


                                </Toolbar>
                            </AppBar>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={7} className={classes.gridLeft}>
                                    <Box className={classes.imageBox}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            {$imagePreview}
                                            <input
                                                accept="image/*"
                                                className={classes.input}
                                                onChange={(e) => onChangeHandler(e)}
                                                id="contained-button-file"
                                                multiple
                                                type="file"
                                            />
                                            <label htmlFor="contained-button-file" className={classes.imageButton}>
                                                <Button variant="contained" color="primary" component="span" className={classes.button}>
                                                    Add Image
                                                </Button>
                                            </label>
                                        </div>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={5} className={classes.gridLeft}>
                                    <Box className={classes.formBox}>
                                        <CssTextField className={classes.fields}
                                            style={{ color: "white" }}
                                            id="outlined-basic"
                                            label="Product Title"
                                            variant="outlined"
                                            className={classes.titleInput}
                                            InputProps={{
                                                className: classes.inputText
                                            }}
                                            onChange={formikProps.handleChange('title')}
                                        />
                                        <CssTextField className={classes.fields}
                                            style={{ color: "white" }}
                                            id="outlined-basic"
                                            label="Price"
                                            variant="outlined"
                                            className={classes.titleInput}
                                            InputProps={{
                                                className: classes.inputText
                                            }}
                                            onChange={formikProps.handleChange('price')}
                                        />

                                        <CssTextField
                                            rows='5'
                                            multiline
                                            id="outlined-basic"
                                            label="Description"
                                            variant="outlined"
                                            className={classes.titleInput}
                                            InputProps={{
                                                className: classes.inputText
                                            }}
                                            onChange={formikProps.handleChange('description')}
                                        />
                                        <Typography id="discrete-slider-small-steps" gutterBottom className={classes.label}>
                                            Warranty (years)
                                        </Typography>
                                        <Slider
                                            className={classes.slider}
                                            defaultValue={0}
                                            // getAriaValueText={valuetext}
                                            aria-labelledby="discrete-slider-small-steps"
                                            step={1}
                                            marks
                                            min={0}
                                            max={15}
                                            valueLabelDisplay="auto"
                                            onChange={
                                                (event, value) =>
                                                    formikProps.setFieldValue('warranty', value)
                                            }
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    )
                }
            </Formik>
        </Dialog>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
    placeHolderImage: {
        width: 'auto',
        height: '100%'
    },
    imageDiv: {
        width: 350,
        height: 250,
    },
    gridLeft: {
        marginTop: '5%',
        [theme.breakpoints.down('sm')]: {
            margin: '0%',
        },
    },
    imageBox: {
        display: 'flex',
        justifyContent: 'center',
    },
    imageButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '2%',
        marginBottom: '2%'
    },
    appBar: {
        position: 'relative',
        backgroundColor: theme.palette.sec
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    titleInput: {
        width: '75%',
        margin: '2%'
    },
    inputText: {
        color: 'white',
    },
    formBox: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
        },
    },
    slider: {
        width: '75%',
        margin: '2%',
        color: theme.palette.pri
    },
    label: {
        width: '75%',
        margin: '2%',
    },
    button: {
        backgroundColor: theme.palette.sec,
        '&:hover': {
            backgroundColor: theme.palette.pri,
        }
    },
}));
const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#c4b205',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: '#c4b205',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#c4b205',
            },
            '& input': {
                color: 'white', // Set the text color to white
            },
        },
    },
})(TextField);

export default CreateProductModal;
