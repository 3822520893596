import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getFirebase } from 'react-redux-firebase';

export const createProduct = (product, onsucess) => {

  return async (dispatch, getState) => {
    console.log('dispatched ')
    try {
      product.title_lowercase = product.title_lowercase.toLowerCase()
      const firebase = getFirebase();
      const firestore = getFirestore(firebase);
      const productsCollection = collection(firestore, "products");
      addDoc(productsCollection, product)
        .then((docRef) => {
          dispatch({ type: "CREATE_PRODUCT", product });
          onsucess()
        })
        .catch((error) => {
          dispatch({ type: "CREATE_PRODUCT_ERROR", error });
        });
    } catch (error) {
      dispatch({ type: "CREATE_PRODUCT_ERROR", error });
    }
  };
};
